<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <!-- fiscal year -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Year" vid="year" rules="required|min_value:1"  v-slot="{ errors }">
              <b-form-group
                label-for="year"
              >
              <template v-slot:label>
                {{$t('globalTrans.year')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="data.year"
                :options="yearList"
                id="year"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Production Target" vid="production_target" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                <b-form-group
                  label-for="production_target"
                >
                  <template v-slot:label>
                    {{ $t('teaGardenService.production_target') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="production_target"
                    v-model="data.production_target"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
        </b-row>
        <b-row>
          <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Production Target" vid="production_target" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                <b-form-group
                  label-cols-sm="5"
                  label-for="production_target"
                >
                  <template v-slot:label>
                    {{ $t('teaGardenService.production_target') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="production_target"
                    v-model="data.production_target"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->
            <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Production Achievement" vid="production_achievement" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
                <b-form-group
                  label-cols-sm="5"
                  label-for="production_achievement"
                >
                  <template v-slot:label>
                    {{ $t('teaGardenService.production_achievement') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="production_achievement"
                    v-model="data.production_achievement"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { annualProductionTargetStore, annualProductionTargetUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  components: { },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        year: 0,
        production_target: '',
        production_achievement: ''
      }
    }
  },
  created () {
    this.data.year = new Date().getFullYear()
    if (this.id) {
      const tmp = this.getAnnualProductionTarget()
      this.data = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    yearList () {
        return this.$store.state.TeaGardenService.commonObj.yearList
      },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${annualProductionTargetUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, annualProductionTargetStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getAnnualProductionTarget () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
